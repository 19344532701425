document.addEventListener("DOMContentLoaded", function () {
	const elements = document.querySelectorAll(`[data-entry-animation]`);
	const checkElements = () => {
		elements.forEach((element) => {
			const rect = element.getBoundingClientRect();
			const visible =
				rect.bottom > window.innerHeight * 0.15 &&
				rect.top <
					window.innerHeight - (window.innerHeight * 0.15 + 60);
			element.toggleAttribute("data-in-view", visible);
		});
	};
	checkElements();
	document.addEventListener("scroll", checkElements);
});
